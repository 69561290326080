






















































































































































.card-header {
  display: flex;
  gap: 3rem;
  justify-content: space-between;

  &-element {

    &-icon {
      margin-right:6px;

      i {
        padding: 12px;
        background-color: #F5F7FA;
        color: #8F9298;
        border-radius: 0;
      }
    }
  }
}

